import openOnTheCloseImage from '../assets/stills/OpenOnTheClose_1.6.1.jpg'
import lurkingImage from '../assets/stills/Lurking_1.2.1.png'
import twenty35Image from '../assets/stills/2035_2.1.1.png'
import soleCustodyImage from '../assets/stills/sole_custody.jpg'
import stableImage from '../assets/stills/stable.png'
import pcImage from '../assets/stills/prison-comedy.jpg'

export const FilmData = [
    {title: 'Open on the Close', 
        date: 'Aug 2023', 
        backgroundImage: openOnTheCloseImage, 
        role:'Editor', 
        director:'Duncan Currie and Jenna Lucas', 
        camera:'Sony FX9', 
        lens:'', 
        url:'https://www.youtube.com/watch?v=IlXbk1gX_kI',
        film_desc:"'Open on the Close' is a short romance that was produced as part of the 48 Hour Film Challenge. This was my first time taking part in such a challenge and allowed me to delve into other creative areas of preproduction that I had less experience in. The process started with a prompt, consisting of a genre, line of dialogue and a prop. We then had 48 hours to create this film from start to finish."},
    {title: 'Stable', 
        date: 'Feb 2023', 
        backgroundImage: stableImage, 
        role:'1st AC', 
        director:'Duncan Currie and Jenna Lucas', 
        camera:'Sony FX9', 
        lens:'Zeiss Prime Set',
        url:'',
        film_desc:"'Stable' is a 3 part sci-fi thriller series. The filming took place over the course of 2 weeks on an Oxfordshire farm. Along with my primary role of 1st AC I also had the responsibility of DIT and so I was also incharge of creating rushes for the directors. The complex shoot had a large amount of props and hair/makeup and to this day remains the largest-budget shoot I have worked on."},
    {title: 'Prison Comedy', 
        date: 'Jan 2022', 
        backgroundImage: pcImage, 
        role:'1st AC', 
        director:'', 
        camera:'BMD Ursa Mini G2', 
        lens:'Zeiss Prime Set',
        url:'',
        film_desc:''},
    {title: 'Sole Custody', 
        date: 'Jul 2021', 
        backgroundImage: soleCustodyImage, 
        role:'Director', 
        director:'James Strachan', 
        camera:'BMPCC 4k', 
        lens:'Meike Prime Set', 
        url:'https://www.youtube.com/watch?v=EFzZuULBG44',
        film_desc:''},
    {title: 'Lurking', 
        date: 'Nov 2021', 
        backgroundImage: lurkingImage, 
        role:'Director of Photography / Editor', 
        director:'Dan McCollum', 
        camera:'BMPCC 4k', 
        lens:'Meike Prime Set', 
        url:'https://youtu.be/WmTsNA_e00Q',
        film_desc:''},
    {title: '2035', 
        date: 'Aug 2020', 
        backgroundImage: twenty35Image, 
        role:'Editor', 
        director:'Karolina Smolova', 
        camera:'BMPCC 4k', 
        lens:'Vintage Prime Set', 
        url:'https://www.youtube.com/watch?v=baWU8-ug1Vk',
        film_desc:"'2035' was an ambitious post-appocalyptic thriller/drama. The shoot involved many complex shots and multiple fast-paced coordinated action scenes, one of which involved more than 20 extras. With over 600gb of raw footage to sort through and edit, this short film remains the largest project I have worked on."},
]